import {useEffect} from "react";

import {sfCenterPos} from "../components/_common/_constants";
import {WorkerUrl} from "../constants/workerUrls";
import {Coordinate} from "../store/types";
import {getGeolocation, setGeolocation} from "../utils/browser-storage/location";
import {CFGeolocation} from "../utils/locationTypes";
import {QueryState, QueryStatus, useQueryController} from "./useQueryController";
import {withValidation} from "../utils/fetch/fetches";

const setDefaultLatLong = () => {
  setGeolocation(sfCenterPos);
  return sfCenterPos;
};

const cfGeolocate = async (): Promise<CFGeolocation> => {
  const response = await withValidation()(`${WorkerUrl.PROD}/geolocation`, {
    method: "POST",
  });

  const body = await response.json();
  return {
    x: body.location.lat,
    y: body.location.lng,
    regionCode: body.location.regionCode,
  };
};

const geolocateAndSave = async (): Promise<Coordinate> => {
  try {
    const position = await cfGeolocate();
    setGeolocation(position);
    return position;
  } catch {
    return setDefaultLatLong();
  }
};

export const geolocateUser = async (forceCfGeolocate = false): Promise<Coordinate> =>
  forceCfGeolocate ? geolocateAndSave() : getGeolocation() || geolocateAndSave();

export const useGeolocateUser = () => {
  useEffect(() => {
    geolocateUser(false);
  }, []);
};

/**
 * Hook for getting the user's current coordinates.
 */
export const useUserCoordinates = (): QueryState<Coordinate> => {
  const coordinates = getGeolocation();
  const query = useQueryController({
    fn: geolocateAndSave,
    cacheKey: "cf-geolocation-query",
    initialValue: sfCenterPos,
    ifRejectedValue: sfCenterPos,
    skip: !!coordinates,
  });
  if (coordinates) {
    return {
      data: coordinates,
      status: QueryStatus.SUCCESS,
      isSettled: true,
      isLoading: false,
      isInitiated: true,
    };
  }
  return query;
};
